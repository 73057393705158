'use client'
import BBBLogo from '@/public/assets/bbb.svg'
import SslSecured from '@/public/assets/ssl_secured.svg'

import StripeLogo from '@/public/assets/stripe.svg'
import TrustPilotLogo from '@/public/assets/trustpilot.svg'
import SensayLogo from '@/public/logos/sensay-logo.svg'
import {
  DiscordLogo,
  FacebookLogo,
  type Icon,
  InstagramLogo,
  LinkedinLogo,
  MediumLogo,
  TelegramLogo,
  TiktokLogo,
  XLogo,
  YoutubeLogo,
} from '@phosphor-icons/react'
import Image from 'next/image'
import Link from 'next/link'
import { Button } from '../ui/Button'
import { Heading } from '../ui/Heading'

const nav: {
  title: string
  items: { title: string; href: string; target?: string; rel?: string }[]
}[] = [
  {
    title: 'Solutions',
    items: [
      { title: 'Dementia', href: '/dementia' },
      { title: 'Education', href: '/education' },
      { title: 'Fan Engagement', href: '/fan-engagement' },
      { title: 'Custom Replicas', href: '/custom-replicas' },
      { title: 'Affiliate', href: '/affiliate' },
      { title: 'Community Replicas', href: '/community' },
    ],
  },
  {
    title: 'For teams',
    items: [
      { title: 'Corporate Knowledge Management', href: '/solutions/corporate-knowledge-management' },
      { title: 'Employee Training', href: '/solutions/employee-training' },
      { title: 'Customer Service', href: '/solutions/customer-service' },
      { title: 'Research & Development', href: '/solutions/research-and-development' },
      { title: 'Brand Representation', href: '/solutions/brand-representation' },
      { title: 'Custom Solutions', href: '/solutions/custom-solutions' },
    ],
  },
  {
    title: 'Support',
    items: [
      { title: '$SNSY', href: 'https://www.snsy.ai/' },
      { title: 'Pricing', href: '/pricing/year' },
      { title: 'Documentation', href: 'https://github.com/sensay-io' },
      {
        title: 'Feedback',
        href: 'https://share.hsforms.com/2CCEWf7wWQdKoO7vaW2BWCAsio0r',
        target: '_blank',
        rel: 'noreferrer noopener',
      },
    ],
  },
  {
    title: 'Company',
    items: [
      { title: 'Shop', href: 'https://shop.sensay.io' },
      { title: 'Apps', href: '/apps' },
      { title: 'About', href: '/about' },
      { title: 'Blog', href: 'https://blog.sensay.io' },
      { title: 'Investors', href: '/investors' },
      { title: 'Partners', href: '/partners' },
      { title: 'Careers', href: '/careers' },
    ],
  },
  {
    title: 'Legal',
    items: [
      { title: 'Privacy', href: '/privacy' },
      { title: 'Terms', href: '/terms' },
      { title: 'Take Down', href: '/take-down' },
      { title: 'CSR', href: '/corporate-social-responsibility' },
      { title: 'Cookie Consent', href: '#consent-banner' },
    ],
  },
]

type CTAProps = {
  heading: string
  buttonText: string
  buttonHref: string
}

type FooterProps = {
  cta?: CTAProps
}

const Footer = ({ cta }: FooterProps) => {
  return (
    <footer className="w-full bg-burgundy">
      {cta && <CTAsection {...cta} />}
      <div className="container mx-auto flex flex-col">
        <div className="flex sm:flex-row flex-col h-full w-full flex-wrap justify-between pb-6 pt-20 md:items-start md:pb-12">
          <Pages />
          <Newsletter />
          <Sensay />
          <Details />
        </div>
      </div>
    </footer>
  )
}

export default Footer

const Pages = () => {
  return (
    <div className="grid w-full min-[400px]:grid-cols-2 gap-y-10 md:grid-cols-4 md:gap-y-0 sm:basis-4/4 lg:basis-3/4">
      {nav.map((item, index) => (
        <div key={index}>
          <Heading level="h4" className="text-xl font-light text-white mb-3">
            {item.title}
          </Heading>
          <div className="grid gap-y-3 text-zinc-400">
            {item.items.map(({ href, title, target, rel }, index) => (
              <p className="grid transition-colors duration-300 hover:text-primary" key={index}>
                <Link href={href} target={target} rel={rel}>
                  {title}
                </Link>
              </p>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

const Newsletter = () => {
  return (
    <div className="lg:max-w-[300px] sm:basis-2/4 lg:basis-1/4 py-10 lg:py-0 mt-12 lg:mt-0">
      <Heading level="h4" className="text-xl font-light text-white mb-3">
        Subscribe to our Newsletter
      </Heading>
      <p className="text-zinc-400 mb-3">The latest news, articles and resources, sent to your inbox.</p>
      <Button variant="secondary" className="w-full" asChild>
        <Link href="https://share.hsforms.com/2Q7jR-6k4TbWjyQVsW-IRXAsio0r" rel="noreferrer noopener" target="_blank">
          Subscribe
        </Link>
      </Button>
    </div>
  )
}

const Sensay = () => {
  return (
    <div className="mx-auto w-full sm:basis-2/4 lg:basis-full mt-12">
      <div className="flex flex-col items-center justify-between gap-9 lg:border-t lg:border-white/10  py-10 lg:py-8 lg:flex-row">
        <div className="flex flex-wrap items-center justify-center gap-6 px-6 lg:justify-start md:px-0">
          <div className="flex items-center justify-center gap-1 text-white md:justify-start">
            <Link href="/" className="transition-colors duration-300 hover:text-primary">
              <SensayLogo className="h-[32px] fill-current" />
            </Link>

            <span className="rounded-md bg-white/10 px-2 py-1 text-xs text-white ring-1 ring-inset ring-white/20">
              βeta
            </span>

            {/* {deploymentEnvironment !== 'production' && (
              <span className="rounded-md bg-red-500 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-gray-500/10">
                {deploymentEnvironment}
              </span>
            )} */}
          </div>

          <Link
            href="https://www.producthunt.com/posts/sensay-replicas?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-sensay&#0045;replicas"
            target="_blank"
            className="opacity-40"
            rel="noreferrer"
          >
            <Image
              src="/assets/producthunt.svg"
              alt="Sensay - Dementia care with lifelike AI replicas | Product Hunt"
              width={200}
              height={40}
            />
          </Link>
        </div>

        <div className="flex-wrap lg:max-w-full w-full max-w-[250px] flex lg:flex-shrink-0 items-center justify-center gap-6 lg:px-12 text-zinc-200 md:w-auto md:px-0">
          <SocialMediaLink name="Facebook" href="https://www.facebook.com/asksensay" icon={FacebookLogo} />
          <SocialMediaLink name="Instagram" href="https://www.instagram.com/asksensay" icon={InstagramLogo} />
          <SocialMediaLink name="Twitter" href="https://www.twitter.com/asksensay" icon={XLogo} />
          <SocialMediaLink name="LinkedIn" href="https://www.linkedin.com/company/asksensay" icon={LinkedinLogo} />
          <SocialMediaLink name="Tiktok" href="https://www.tiktok.com/@asksensay" icon={TiktokLogo} />
          <SocialMediaLink name="Facebook" href="https://www.youtube.com/@asksensay" icon={YoutubeLogo} />
          <SocialMediaLink name="Discord" href="https://discord.com/invite/sensay" icon={DiscordLogo} />
          <SocialMediaLink name="Facebook" href="https://t.me/asksensay" icon={TelegramLogo} />
          <SocialMediaLink name="Medium" href="https://blog.sensay.io" icon={MediumLogo} />
        </div>
      </div>
    </div>
  )
}

const Details = () => {
  return (
    <div className="flex flex-col-reverse w-full sm:basis-4/4 lg:basis-full items-center gap-6 border-t border-white/10 px-6 py-8 text-zinc-400 lg:items-start lg:justify-between lg:px-0 lg:flex-row">
      <div className="mt-6 md:mt-0 flex flex-col gap-1">
        <p className="text-center text-xs leading-5 lg:text-left">&copy; 2024 Sensay. All rights reserved</p>
        <Link
          target="_blank"
          href={'https://etherscan.io/address/0x82a605D6D9114F4Ad6D5Ee461027477EeED31E34'}
          className="cursor-pointer text-center text-xs leading-5 duration-150 hover:text-zinc-50 lg:text-left"
        >
          $SNSY Ethereum Network: 0x82a605D6D9114F4Ad6D5Ee461027477EeED31E34
        </Link>
        <Link
          target="_blank"
          href={'https://arbiscan.io/token/0x3124678d62d2aa1f615b54525310fbfda6dcf7ae'}
          className="cursor-pointer text-center text-xs leading-5 duration-150 hover:text-zinc-50 lg:text-left"
        >
          $SNSY Arbitrum & Base: 0x3124678D62D2aa1f615B54525310fbfDa6DcF7AE
        </Link>
      </div>

      <div className="mx-auto flex flex-wrap items-center justify-center gap-6 opacity-60 md:mx-0 max-w-none md:gap-12">
        <SslSecured className="h-10 shrink-0" />
        <BBBLogo className="h-14 shrink-0" />
        <Link href="https://uk.trustpilot.com/review/sensay.io">
          <TrustPilotLogo className="h-6 shrink-0" />
        </Link>
        <StripeLogo className="h-8 shrink-0" />
      </div>
    </div>
  )
}

const SocialMediaLink = ({ href, icon: Icon, name }: { href: string; icon: Icon; name: string }) => (
  <Link href={href} target="_blank" className="cursor-pointer transition-colors duration-300 hover:text-primary">
    <span className="sr-only">{name}</span>
    <Icon aria-hidden="true" className="h-[24px] w-[24px]" />
  </Link>
)

const CTAsection = ({ heading, buttonText, buttonHref }: CTAProps) => (
  <section className="container mx-auto px-6">
    <div className="flex flex-col py-20 gap-[60px] border-b border-light-plum">
      <Heading level="h2" className="text-center max-w-4xl font-light mx-auto text-off-white text-balance">
        {heading}
      </Heading>
      <div className="flex items-center justify-center mx-auto gap-5">
        <Button asChild>
          <Link href={buttonHref}>{buttonText}</Link>
        </Button>
      </div>
    </div>
  </section>
)
