import { type VariantProps, cva } from 'class-variance-authority'
import { createElement } from 'react'
import { twMerge } from 'tailwind-merge'

type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

const headingVariants = cva('scroll-m-20 font-butler text-burgundy', {
  variants: {
    level: {
      h1: 'text-[2.6rem] md:text-[2.85rem] lg:text-[4rem] leading-[1.1] font-normal',
      h2: 'text-[2.5rem] md:text-[3rem] lg:text-[3rem] leading-[1.1] font-normal',
      h3: 'text-[1.75rem] md:text-[2rem] lg:text-[2.1875rem] leading-[1.1] font-normal',
      h4: 'text-[1.125rem] md:text-[1.25rem] leading-[1.2] font-sourcesanspro font-semibold',
      h5: 'text-[1rem] md:text-[1.125rem] leading-[1.2] font-sourcesanspro font-semibold',
      h6: 'text-base leading-[1.2] font-sourcesanspro font-semibold',
    },
  },
})

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement>, VariantProps<typeof headingVariants> {
  level: HeadingLevel
  children: React.ReactNode
}
export function Heading({
  level,
  children,
  className,
  id,
  htmlFor,
  ...props
}: HeadingProps & { id?: string; htmlFor?: string }) {
  return createElement(
    level,
    {
      className: twMerge(headingVariants({ level, className })),
      id,
      htmlFor,
      ...props,
    },
    children,
  )
}
