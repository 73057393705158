import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@/lib/utils'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-[16px] font-sourcecodepro font-light leading-[20.11px] text-center transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:bg-slate-200 disabled:text-slate-400 disabled:border-slate-200 [&_svg]:pointer-events-none [&_svg]:shrink-0 w-fit',
  {
    variants: {
      variant: {
        default:
          'bg-bright-plum text-off-white shadow hover:bg-bright-plum-light hover:text-off-white active:bg-bright-plum-light active:text-off-white',
        secondary:
          'bg-bright-plum-7 text-bright-plum border border-bright-plum-50 shadow-sm hover:border-bright-plum-50 hover:text-bright-plum hover:bg-bright-plum-25 active:border-bright-plum-50 active:text-bright-plum active:bg-bright-plum-50',
        'secondary-active':
          'active:border-bright-plum-50 active:text-bright-plum active:bg-bright-plum-50 disabled:border-bright-plum-50 disabled:text-bright-plum disabled:bg-bright-plum-50',
        'secondary-white':
          'bg-burgundy text-off-white border border-off-white shadow-sm hover:border-bright-plum-50 hover:text-bright-plum hover:bg-bright-plum-25 active:border-bright-plum-50 active:text-bright-plum active:bg-bright-plum-50',
        ghost: 'text-bright-plum hover:bg-bright-plum-7 active:bg-bright-plum-25',
        red: 'bg-red text-off-white hover:bg-red-light active:bg-red-intense',
        white: 'bg-white text-bright-plum border border-white hover:bg-bright-plum-25 active:bg-bright-plum-50',
        link: 'text-bright-plum underline !p-0',
      },
      size: {
        default: 'h-[52px] gap-[8px] px-[16px] rounded-[4px] text-[16px] [&_svg]:size-6',
        medium: 'h-[44px] gap-[8px] px-[16px] rounded-[4px] text-[15px] [&_svg]:size-4',
        small: 'h-[33px] gap-[4px] px-[12px] rounded-[4px] text-[15px] [&_svg]:size-3',
        icon: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  theme?: 'light' | 'dark'
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant = 'default', size = 'default', theme = 'light', asChild = false, disabled, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, className }),
          theme,
          asChild && disabled && 'pointer-events-none bg-slate-200 text-slate-400 border-slate-200',
        )}
        ref={ref}
        disabled={disabled}
        {...props}
      />
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
